import { Form, Button, Input, Modal, Table, notification, Tag, Space, Select, InputNumber } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { PlusCircleTwoTone, MinusCircleOutlined, PlusOutlined, CloudDownloadOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { EditFilled } from '@ant-design/icons'
import './styles.css'

import { Context } from '../../context'
import { Fetch, Post, Put, Patch } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'
import MultiCurrencyInput from '../MultiCurrencyInput/index'
const { Column } = Table
const { Option } = Select
const { TextArea } = Input


const ManagePlans = () => {
  const { token, apiUrl } = useContext(Context)

  const [plans, setPlans] = useState([])
  const [applePlans, setApplePlans] = useState([])
  const [androidPlans, setAndroidPlans] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [planId, setPlanId] = useState()
  const [editPlan, setEditPlan] = useState({ price: {} })
  const [pageType, setPageType] = useState('web')
  const [data, setData] = useState()

  const [form] = Form.useForm()

  useEffect(() => {
    if (pageType === "web") {
      setData(plans)
    }
    if (pageType === "apple") {
      setData(applePlans)
    }
    if (pageType === "android") {
      setData(androidPlans)
    }
  })

  useEffect(() => {
    if (pageType === 'web') {
      Fetch(`${apiUrl}/plans/cms?type=${pageType}`, token, (result) => {
        const plans = convertIdToKey(result.plans)
        plans.forEach(plan => {
          const type_of_isprivate = typeof (plan.isPrivate);

          if ((type_of_isprivate === "undefined") || (plan.isPrivate === "False")) {
            plan.isPrivate = "public";
          } else {
            plan.isPrivate = "private";
          }
        })
        setPlans(plans)
      }
      )
    }
    if (!planId) {
      return
    }

    Fetch(`${apiUrl}/plans/${planId}`, token, (result) => {
      const { ...others } = result;
      console.log("hello");
      form.setFieldsValue(others);
    })
  }, [apiUrl, token, pageType, confirmLoading])

  useEffect(() => {
    if (pageType === 'apple') {
      Fetch(`${apiUrl}/plans/cms?type=${pageType}`, token, (result) => {
        const apple_plans = convertIdToKey(result.plans)

        apple_plans.forEach(plan => {
          if (!plan.name) {
            plan.name = plan.planId
          }
        })
        setApplePlans(apple_plans);
      })
    }
  }, [apiUrl, token, pageType, confirmLoading])

  useEffect(() => {
    if (pageType === 'android') {
      Fetch(`${apiUrl}/plans/cms?type=${pageType}`, token, (result) => {
        const android_plans = convertIdToKey(result.plans)

        android_plans.forEach(plan => {
          if (!plan.name) {
            plan.name = plan.planId
          }
        })
        setAndroidPlans(android_plans);
      })
    }
  }, [apiUrl, token, pageType, confirmLoading])

  const handleSubmit = async () => {
    console.log("PageType", pageType)
    if (pageType === 'web') {
      try {
        setConfirmLoading(true)
        let price = form.getFieldValue('price')
        let price_t = {} // to store the sales price keys and values
        let mrp_t = {} // to store the mrp keys and values
        Object.entries(price).map(([k, v]) =>
          (k.includes("_MRP") ? (mrp_t[k] = v) : (price_t[k] = v)))
        let values = form.getFieldsValue()
        values = { ...values, price: price_t, mrp: mrp_t }
        Post(
          `${apiUrl}/plans`,
          token,
          { ...values },
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Plan created'
              })
            }
            form.resetFields()
            setModalVisible(false)
            setConfirmLoading(false)
          },
          () => {
            setConfirmLoading(false)
            notification.error({
              message: 'Add Plan Error',
              description: 'Check console for more details'
            })
          }
        )
      } catch (err) {
        setConfirmLoading(false)
        console.error(err)
        notification.error({
          message: 'Add Plan Error',
          description: 'Required fields are incorrect/missing'
        })
      }
    }

    if (pageType === 'apple') {
      try {
        setConfirmLoading(true)
        let values = form.getFieldsValue()
        console.log(values.planId)
        Post(
          `${apiUrl}/plans/applePlan`,
          token,
          { ...values, planType: "applePlan", },
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Plan created'
              })
            }
            form.resetFields()
            setModalVisible(false)
            setConfirmLoading(false)
          },
          () => {
            setConfirmLoading(false)
            notification.error({
              message: 'Add Plan Error',
              description: 'Check console for more details'
            })
          }
        )
      } catch (err) {
        setConfirmLoading(false)
        console.error(err)
        notification.error({
          message: 'Add Plan Error',
          description: 'Required fields are incorrect/missing'
        })
      }
    }

    if (pageType === 'android') {
      try {
        setConfirmLoading(true)
        let values = form.getFieldsValue()
        console.log(values.planId)
        Post(
          `${apiUrl}/plans/androidPlan`,
          token,
          { ...values, planType: "androidPlan", },
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Plan created'
              })
            }
            form.resetFields()
            setModalVisible(false)
            setConfirmLoading(false)
          },
          () => {
            setConfirmLoading(false)
            notification.error({
              message: 'Add Plan Error',
              description: 'Check console for more details'
            })
          }
        )
      } catch (err) {
        setConfirmLoading(false)
        console.error(err)
        notification.error({
          message: 'Add Plan Error',
          description: 'Required fields are incorrect/missing'
        })
      }
    }

  }

  const getPlanDetails = async (id) => {
    console.log("pageType: ", pageType)
    if (pageType === 'web') {
      Fetch(`${apiUrl}/plans/cms/${id}?type=${pageType}`, token, (result) => {
        const { ...others } = result;
        // console.log("before", result)
        setEditPlan({ ...result, price: { ...result.price, ...result.mrp } })
        // console.log("result", result);
        others.price = { ...result.price, ...result.mrp }
        console.log("others", others)
        form.setFieldsValue(others);
        setPlanId(id);
      })
    }
    if (pageType === 'apple') {
      Fetch(`${apiUrl}/plans/cms/${id}?type=${pageType}`, token, (result) => {
        setEditPlan({ planId: result.planId })
        console.log("result: ", result)
        form.setFieldValue("planId", result.planId);
        form.setFieldValue("price", result.price);
        form.setFieldValue("duration", result.duration);
        form.setFieldValue("description", result.description);
        setPlanId(id);
      })
    }
    if (pageType === 'android') {
      Fetch(`${apiUrl}/plans/cms/${id}?type=${pageType}`, token, (result) => {
        setEditPlan({ planId: result.planId })
        console.log("result: ", result)
        form.setFieldValue("planId", result.planId);
        form.setFieldValue("price", result.price);
        form.setFieldValue("duration", result.duration);
        setPlanId(id);
      })
    }

  }

  const handleEditSubmit = async () => {
    if (pageType === 'web') {
      try {
        await setConfirmLoading(true)
        let price = form.getFieldValue('price')
        let price_t = {} // to store the sales price keys and values
        let mrp_t = {} // to store the mrp keys and values
        Object.entries(price).map(([k, v]) =>
          (k.includes("_MRP") ? (mrp_t[k] = v) : (price_t[k] = v)))
        let values = form.getFieldsValue()
        values = { ...values, price: price_t, mrp: mrp_t }
        // console.log(values)
        Put(
          `${apiUrl}/plans/${planId}`,
          token,
          { ...values },
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Edited successfully'
              })
              form.resetFields()
              setEditModalVisible(false)
              setPlanId(null)
              setConfirmLoading(false)
            }
          },
          () => {
            setConfirmLoading(false)
            notification.error({
              message: 'Error updating plan',
              description: 'Please check logs for more details'
            })
          }
        )
      } catch (err) {
        setConfirmLoading(false)
        console.error(err)
        notification.error({
          message: 'Edit Plan Error',
          description: 'Required fields are incorrect/missing'
        })
      }
    }
    if (pageType === 'apple') {
      console.log("pageEdit: ", pageType)
      try {
        await setConfirmLoading(true)
        let values = form.getFieldsValue()
        console.log("Edit Values: ", values)
        Put(
          `${apiUrl}/plans/applePlan/${planId}`,
          token,
          { ...values, planType: "applePlan" },
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Edited successfully'
              })
              form.resetFields()
              setEditModalVisible(false)
              setPlanId(null)
              setConfirmLoading(false)
            }
          },
          () => {
            setConfirmLoading(false)
            notification.error({
              message: 'Error updating plan',
              description: 'Please check logs for more details'
            })
          }
        )
      } catch (err) {
        setConfirmLoading(false)
        console.error(err)
        notification.error({
          message: 'Edit Plan Error',
          description: 'Required fields are incorrect/missing'
        })
      }
    }
    if (pageType === 'android') {
      console.log("pageEdit: ", pageType)
      try {
        await setConfirmLoading(true)
        let values = form.getFieldsValue()
        console.log("Edit Values: ", values)
        Put(
          `${apiUrl}/plans/androidPlan/${planId}`,
          token,
          { ...values, planType: "androidPlan" },
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Edited successfully'
              })
              form.resetFields()
              setEditModalVisible(false)
              setPlanId(null)
              setConfirmLoading(false)
            }
          },
          () => {
            setConfirmLoading(false)
            notification.error({
              message: 'Error updating plan',
              description: 'Please check logs for more details'
            })
          }
        )
      } catch (err) {
        setConfirmLoading(false)
        console.error(err)
        notification.error({
          message: 'Edit Plan Error',
          description: 'Required fields are incorrect/missing'
        })
      }
    }
  }

  const toggleState = async (planId) => {
    console.log(planId)
    console.log("Page for Toggling Status: ", pageType)
    if (pageType === 'web') {
      const toggledFlag = !plans.filter(p => p.key === planId)[0].suspended
      try {
        await setConfirmLoading(true)
        Patch(
          `${apiUrl}/plans/${planId}`,
          token,
          { suspended: toggledFlag },
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Plan state toggled successfully'
              })
            }
          },
          () => {
            notification.error({
              message: 'Plan State Toggle Error',
              description: 'Check console for more details'
            })
          }
        )
      } finally {
        await setConfirmLoading(false)
      }
    }
    if (pageType === "apple") {
      const toggledFlag = !applePlans.filter(p => p.key === planId)[0].suspended
      try {
        await setConfirmLoading(true)
        Patch(
          `${apiUrl}/plans/applePlan/${planId}`,
          token,
          { suspended: toggledFlag },
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Plan state toggled successfully'
              })
            }
          },
          () => {
            notification.error({
              message: 'Plan State Toggle Error',
              description: 'Check console for more details'
            })
          }
        )
      } finally {
        await setConfirmLoading(false)
      }
    }

    if (pageType === "android") {
      const toggledFlag = !androidPlans.filter(p => p.key === planId)[0].suspended
      try {
        await setConfirmLoading(true)
        Patch(
          `${apiUrl}/plans/androidPlan/${planId}`,
          token,
          { suspended: toggledFlag },
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Plan state toggled successfully'
              })
            }
          },
          () => {
            notification.error({
              message: 'Plan State Toggle Error',
              description: 'Check console for more details'
            })
          }
        )
      } finally {
        await setConfirmLoading(false)
      }
    }
  }

  const handlePageChange = (page) => {
    console.log("PageChange: ", page)
    if (page === "web") {
      setPageType("web")
      setData(plans)
    }
    if (page === "apple") {
      setPageType("apple")
      setData(applePlans)
    }
    if (page === "android") {
      setPageType("android")
      setData(androidPlans)
    }
  }

  return (
    <>
      <div className="manage-plans-wrapper">
        <Space direction='horizontal' size={"middle"}>
          <Select
            style={{ width: 200, marginBottom: 20, }}
            placeholder="Select Plan Type"
            showSearch
            onChange={handlePageChange}
            defaultValue='web'
          >
            <Option value="web">Web Plans</Option>
            <Option value="apple">Apple Plans</Option>
            {/* <Option value="android">Google Play Plans</Option> */}
          </Select>
        </Space>
        <Table dataSource={data}>
          <Column title="Name" dataIndex="name" key="name" align="center" />
          {pageType === "web" && (<Column title="Price" dataIndex="price" key="price" align="center"
            render={(_, record) => record.price && Object.entries(record.price).map(([cc, price]) => (
              <Tag>{cc} : {price}</Tag>
            ))}
          />)}
          {pageType === "web" && (<Column
            title="Duration (days)"
            dataIndex="duration"
            key="duration"
            align="center"
          />)}
          <Column
            title="Status"
            dataIndex="suspended"
            key="suspended"
            align="center"
            render={v => v ? 'Suspended' : 'Active'}
          />
          <Column
            title="Actions"
            key="action"
            align="center"
            render={(text, record) => (
              <Space size="middle">
                <a
                  onClick={() => {
                    setPlanId(record.key);
                    getPlanDetails(record.key);
                    setEditModalVisible(true);
                    console.log(record.key, 'recordkey for edit or toggleStatus');
                  }}
                >
                  {<EditFilled />}
                </a>
                <a onClick={() => toggleState(record.key)}>
                  {record.suspended ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
                </a>
              </Space>
            )}
          />
        </Table>
        <PlusCircleTwoTone
          twoToneColor="#FF0091"
          style={{ fontSize: '48px' }}
          className="add-plan-btn"
          onClick={() => { setModalVisible(true); console.log(pageType) }}
        />
        <CloudDownloadOutlined
          className="export-btn"
          onClick={() => handleExportClick(apiUrl, token, 'plans')}
        />
        <Modal
          title="Add Plan"
          visible={modalVisible}
          onOk={handleSubmit}
          okText="Submit"
          onCancel={() => { setModalVisible(false); form.resetFields(); }}
          confirmLoading={confirmLoading}
          maskClosable={false}
          destroyOnClose={true}
        >
          <Form form={form} layout="vertical" initialValues={{ gender: 'M' }}>
            {pageType === "web" && (<>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item
                name="price"
                label={`Price`}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <MultiCurrencyInput form={form} fieldName={'price'} >Currency</MultiCurrencyInput>
              </Form.Item>
              <Form.Item
                name="duration"
                label="Duration"
                rules={[{ required: true }]}
              >
                <Select disabled={false}>
                  <Select.Option key={7} value={7}>
                    {"Weekly"}
                  </Select.Option>
                  <Select.Option key={30} value={30}>
                    {"Monthly"}
                  </Select.Option>
                  <Select.Option key={90} value={90}>
                    {"Quarterly"}
                  </Select.Option>
                  <Select.Option key={365} value={365}>
                    {"Yearly"}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.List
                name="features"
                rules={[
                  {
                    validator: async (_, features) => {
                      if (!features || features.length < 2)
                        return Promise.reject(new Error('At least 2 features'));
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        label={index === 0 ? 'Features' : ''}
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                'Please input feature or delete this field.',
                            },
                          ]}
                          noStyle
                        >
                          <Input style={{ width: '95%' }} />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-btn"
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        Add feature
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </>
            )}
            {(pageType === "apple") && (<>
              <Form.Item name="planId" label="Plan ID" rules={[{ required: true, }]}>
                <Input placeholder='Enter the Plan ID' />
              </Form.Item>
              <Form.Item name={"price"} label="Price" rules={[{ required: true }]}>
                <InputNumber placeholder='Enter the Price in USD' />
              </Form.Item>
              <Form.Item name={"description"} label="Description" rules={[{ required: true }]}>
                <TextArea rows={4} placeholder={"Enter description"} />
              </Form.Item>
              <Form.Item
                name="duration"
                label="Duration"
                rules={[{ required: true }]}
              >
                <Select disabled={false}>
                  <Select.Option key={7} value={7}>
                    {"Weekly"}
                  </Select.Option>
                  <Select.Option key={30} value={30}>
                    {"Monthly"}
                  </Select.Option>
                  <Select.Option key={90} value={90}>
                    {"Quarterly"}
                  </Select.Option>
                  <Select.Option key={365} value={365}>
                    {"Yearly"}
                  </Select.Option>
                </Select>
              </Form.Item>
            </>)}
            {(pageType === "android") && (<>
              <Form.Item name="planId" label="Plan ID" rules={[{ required: true, }]}>
                <Input placeholder='Enter the Plan ID' />
              </Form.Item>
              <Form.Item name={"price"} label="Price" rules={[{ required: true }]}>
                <InputNumber placeholder='Enter the Price in INR' />
              </Form.Item>
              <Form.Item
                name="duration"
                label="Duration"
                rules={[{ required: true }]}
              >
                <Select disabled={false}>
                  {/* <Select.Option key={7} value={7}>
                    {"Weekly"}
                  </Select.Option> */}
                  <Select.Option key={30} value={30}>
                    {"Monthly"}
                  </Select.Option>
                  <Select.Option key={90} value={90}>
                    {"Quarterly"}
                  </Select.Option>
                  <Select.Option key={365} value={365}>
                    {"Yearly"}
                  </Select.Option>
                </Select>
              </Form.Item>
            </>)}
          </Form>
        </Modal>
        <Modal
          title="Edit Plan"
          visible={editModalVisible}
          onOk={handleEditSubmit}
          okText="Update"
          onCancel={() => { setEditModalVisible(false); form.resetFields() }}
          confirmLoading={confirmLoading}
          maskClosable={false}
        >
          <Form form={form} layout="vertical" initialValues={{ gender: 'M' }}>
            {pageType === "web" && (
              <>
                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="price"
                  label={`Price`}
                  rules={[
                    {
                      required: true,
                    }
                  ]}
                >
                  <MultiCurrencyInput value={{ ...editPlan.price }} form={form} fieldName={'price'} ></MultiCurrencyInput>
                </Form.Item>
                <Form.Item
                  name="duration"
                  label="Duration"
                  rules={[{ required: true }]}
                >
                  <Select disabled={true}>
                    <Select.Option key={7} value={7}>
                      {"Weekly"}
                    </Select.Option>
                    <Select.Option key={30} value={30}>
                      {"Monthly"}
                    </Select.Option>
                    <Select.Option key={90} value={90}>
                      {"Quarterly"}
                    </Select.Option>
                    <Select.Option key={365} value={365}>
                      {"Yearly"}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.List
                  name="features"
                  rules={[
                    {
                      validator: async (_, features) => {
                        if (!features || features.length < 2)
                          return Promise.reject(new Error('At least 2 features'));
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          label={index === 0 ? 'Features' : ''}
                          required={false}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message:
                                  'Please input feature or delete this field.',
                              },
                            ]}
                            noStyle
                          >
                            <Input style={{ width: '95%' }} />
                          </Form.Item>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-btn"
                              onClick={() => remove(field.name)}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add feature
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            )}
            {(pageType === "apple") && (<>
              <Form.Item name="planId" label="Pland ID" rules={[{ required: true, }]}>
                <Input placeholder="Enter Plan ID" />
              </Form.Item>
              <Form.Item name={"price"} label="Price" rules={[{ required: true }]}>
                <InputNumber placeholder='Enter the Price in USD' />
              </Form.Item>
              <Form.Item name={"description"} label="Description" rules={[{ required: true }]}>
                <TextArea rows={4} placeholder={"Enter description"} />
              </Form.Item>
              <Form.Item
                name="duration"
                label="Duration"
                rules={[{ required: true }]}
              >
                <Select disabled={false}>
                  {/* <Select.Option key={7} value={7}>
                    {"Weekly"}
                  </Select.Option> */}
                  <Select.Option key={30} value={30}>
                    {"Monthly"}
                  </Select.Option>
                  <Select.Option key={90} value={90}>
                    {"Quarterly"}
                  </Select.Option>
                  <Select.Option key={365} value={365}>
                    {"Yearly"}
                  </Select.Option>
                </Select>
              </Form.Item>
            </>)}
            {(pageType === "android") && (<>
              <Form.Item name="planId" label="Pland ID" rules={[{ required: true, }]}>
                <Input placeholder="Enter Plan ID" />
              </Form.Item>
              <Form.Item name={"price"} label="Price" rules={[{ required: true }]}>
                <InputNumber placeholder='Enter the Price in INR' />
              </Form.Item>
              <Form.Item
                name="duration"
                label="Duration"
                rules={[{ required: true }]}
              >
                <Select disabled={false}>
                  {/* <Select.Option key={7} value={7}>
                    {"Weekly"}
                  </Select.Option> */}
                  <Select.Option key={30} value={30}>
                    {"Monthly"}
                  </Select.Option>
                  <Select.Option key={90} value={90}>
                    {"Quarterly"}
                  </Select.Option>
                  <Select.Option key={365} value={365}>
                    {"Yearly"}
                  </Select.Option>
                </Select>
              </Form.Item>
            </>)}
          </Form>
        </Modal>
      </div>
    </>
  );
}

export default ManagePlans