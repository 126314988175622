import { Button, Space, Select,Col, InputNumber } from 'antd'
import { useEffect, useState } from 'react'

import { DeleteFilled } from '@ant-design/icons'
import { currencycodes } from '../../utils'


const MultiCurrencyInput = ({ value = {} ,onChange , form , fieldName  }) => {

    // for all codes
    //const codes = Object.keys(currencycodes );
    const codes = ['INR', 'NPR', 'MUR', 'SGD', 'AED', 'EUR','USD'];
    const defaultmap = { 'INR' : 1 , 'USD': 1 ,
    'EUR': 1 , 'SGD': 1 , 'AED' : 1 , 'NPR': 1 , 'MUR' : 1}
    const [result,setResult] = useState({})
    const [oldKey,setOldKey] = useState('');
    const data = form.getFieldValue(fieldName);
    const triggerChange = (result,op) => {
      if (op === 'select')  
      delete result[''];
      result = { ...defaultmap , ...result}
      form.setFieldsValue({ [fieldName] : result })
      onChange?.({...result});
    };
  
    useEffect(()=> {
      let tempResult = { ...defaultmap  , ...data}
      setResult(tempResult)
      if(data && Object.keys(data).length < 7)
        triggerChange(tempResult)
    },[data])
    
    const handleNumberChange = (value,code) => {
      console.log(value,code)        
      let t = { ...result , [code]:value};
      setResult(t);
      triggerChange(t, 'number'); 
    }
  
  
    return (
      <>
       
      {
      Object.entries(result).map(([code,amount],index) => 
       code === undefined ? null :
      <><Space direction='horizontal' style={{width:'100%'}}>
        <Select
          showSearch
          optionFilterProp="children"
          id={`${code}-select`}
          value={code}
          onDropdownVisibleChange={(open) =>
          { 
            if(open){
                setOldKey(code);
            }
          }}
          onChange={(_,option) =>
            { let t = {};
              t[option.key] = amount;
              delete result[oldKey];
            setResult({ ...result , ...t });
            triggerChange({ ...result , ...t } , 'select');
          } }
          style={{
            width: '200px',
            margin: '0 8px',
          }}
        >
          <Select.Option key={''} value={''}  label={''}>{ ''}</Select.Option>
          {
            codes.map(cc => 
              <Select.Option key={cc} value={cc} disabled={cc in result} >{ currencycodes[cc] }</Select.Option>
              )
          }
        </Select>
        <InputNumber
          width={'150px'}
          type={'number'}
          id={code}
          min={ fieldName === 'subscriberPrice' ? 0 : 1}
          max={9999}
          value={amount}
          defaultValue={amount}
          onChange={(value)=>handleNumberChange(value,code)}
          style={{
            width: 100,
          }}
        />
        {/* index === 0? null :
        <DeleteFilled
          id={`${code}-delete`}  
          onClick={(e) =>  
          {
            let pri = { ...result};
            delete pri[e.currentTarget.id.split('-')[0]];
            setResult(pri);
            triggerChange(pri,'delete');
          }}
        style={{fontSize: 18, color: 'grey' }} ></DeleteFilled>   */}
      </Space><br/></>
        )}
      {/*<Col span={24} push={18}>
        { 
          codes.find(cc =>  Object.keys(result).includes(cc) === false  ) ? 
          <Button size='middle'
          onClick={(e)=>  
          { let pri = { ...result , '' : 0  };
            setResult(pri); 
            triggerChange(pri,'add');
          }}
         >
            Add Price
          </Button> :
      </Col>*/} </>
    );
  };


  export default MultiCurrencyInput;
