import { useContext, useEffect, useState } from "react";
import { DatePicker, Select, Table, Row, Col, Tabs, Tag, Input } from "antd";
import moment from "moment";
import { Context } from "../../context";
import { Fetch } from "../../fetch";
import { convertIdToKey, handleExportClick } from "../../utils";

const { Column } = Table;
const dateFormat = "YYYY-MM-DD";
const { TabPane } = Tabs;

const ContentReports = () => {
  const { token, apiUrl } = useContext(Context);

  const [activeTab, setActiveTab] = useState("1");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [filters, setFilters] = useState({
    date: [moment().startOf("month"), moment().endOf("month")],
    plan: null,
    paid: null,
    currency: null,
    model: null,
    country: null,
    platform: null,
    content: null,
  });
  const [plans, setPlans] = useState([]);

  const handleTableDataChange = (page) => {
    let f = filters;
    let planstring = f.plan ? `plan=${f.plan}` : ``;
    let sdate = moment(f.date[0]).isValid()
      ? moment(f.date[0]).format(dateFormat)
      : null;
    let edate = moment(f.date[1]).isValid()
      ? moment(f.date[1]).format(dateFormat)
      : null;
    let datestring =
      sdate && edate ? `start_date=${sdate}&end_date=${edate}` : ``;
    let paidstring = f.paid ? `paid=${true}` : ``;
    let currencystring = f.currency ? `currency=${f.currency}` : ``;
    let fstring = `${planstring}&${datestring}&${paidstring}&${currencystring}`;
  };

  useEffect(() => {
    handleTableDataChange(pagination);
  }, [apiUrl, token, filters]);

  const customfilters = (
    <>
      {" "}
      <Row style={{ marginBottom: "20px" }} align={"middle"}>
        {["1", "2", "3", "4", "5"].includes(activeTab) && (
          <Col span={6}>
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              defaultValue={[
                moment(filters.date[0], dateFormat),
                moment(filters.date[1], dateFormat),
              ]}
              onChange={(value, dateString) =>
                setFilters({
                  ...filters,
                  date: dateString ? dateString : [null, null],
                })
              }
              format={dateFormat}
            />
          </Col>
        )}
        {["1", "3"].includes(activeTab) && (
          <Col span={4}>
            <Select
              style={{ width: "100%" }}
              placeholder={"Search By Content"}
              allowClear
              showSearch
              onChange={(value) => setFilters({ ...filters, content: value })}
            ></Select>
          </Col>
        )}
        {["1"].includes(activeTab) && (
          <Col
            span={8}
            align="right"
            push={activeTab == "1" ? 4 : 6}
            style={{ fontSize: 18 }}
          >
            {activeTab == "1" ? ` Total Minutes of consumption - ${0}` : null}
          </Col>
        )}
      </Row>
    </>
  );

  const data1 = [
    { date: "20-06-2022", content: "Hero Gamchawala", minutes: 2000 },
    { date: "20-06-2022", content: "Dabang mora balma", minutes: 1000 },
  ];

  const data2 = [
    { date: "20-06-2022", hour: 1, userCount: 20 },
    { date: "20-06-2022", hour: 24, userCount: 80 },
  ];

  const data3 = [
    {
      content: "Hero Gamchawala",
      uniqueUsers: 5,
      apd: 70,
      progress: 70,
      apd_uu: 14,
      duration: 100,
    },
    {
      content: "Dabang mora balma",
      duration: 160,
      uniqueUsers: 15,
      apd: 60,
      progress: 37.5,
      apd_uu: 4,
    },
  ];

  return (
    <div className="manage-orders-wrapper">
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={(v) => setActiveTab(v)}
      >
        <TabPane tab="Total Minutes consumed" key="1">
          {customfilters}
          <Table dataSource={data1}>
            <Column
              title="Content Title"
              dataIndex="content"
              key="content"
              align="center"
            />
            <Column
              title="Minutes viewed"
              dataIndex={"minutes"}
              key="minutes"
              align="center"
            />
          </Table>
        </TabPane>
        <TabPane tab="Hourly concurrent users" key="2">
          {customfilters}
          <Table dataSource={data2}>
            <Column
              title="Hour (1-24)"
              dataIndex="hour"
              key="hour"
              align="center"
            />
            <Column
              title="No of Users"
              dataIndex={"userCount"}
              key={"userCount"}
              align="center"
            />
          </Table>
        </TabPane>
        <TabPane tab="Content reports" key="3">
          {customfilters}
          <Table
            dataSource={data3}
            pagination={pagination}
            onChange={handleTableDataChange}
          >
            <Column
              title="Content Title"
              dataIndex="content"
              key="content"
              align="center"
            />
            <Column
              title="Duration"
              dataIndex="duration"
              key="duration"
              align="center"
            />
            <Column
              title="No of UU"
              dataIndex="uniqueUsers"
              key="uniqueUsers"
              align="center"
            />
            <Column
              title="APD in mins"
              dataIndex="apd"
              key="apd"
              align="center"
            />
            <Column
              title="Progress(%)"
              dataIndex="progress"
              key="progress"
              align="center"
            />
            <Column
              title="APD/UU"
              dataIndex="apd_uu"
              key="apd_uu"
              align="center"
            />
          </Table>
        </TabPane>
      </Tabs>

      {/* <CloudDownloadOutlined
        className="export-btn-only"
        onClick={() => handleExportClick(apiUrl, token, "orders")}
        />*/}
    </div>
  );
};

export default ContentReports;
